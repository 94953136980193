import React, { useEffect, useReducer, useState } from "react";
import { Modal, ModalBody, ModalClose, ViewportCenteredFixed } from "../modals/Modal";
import { StyledOverlayBackdrop } from "../modals/StyledOverlayBackdrop";
import { TriageModal, TriageModalBody } from "../modals/TriageModal";
import { postDebtAdvisorSession, postUserInterestLog, track } from "../analytics/track";
import { EVENTS } from "@upsolve/shared";
import { $TSFixMe } from "@upsolve/shared/dist/types";
import { flattenDeep } from "lodash";
import { initialFormState, screens, TFormState } from "./Screens";
import { ResultsComparison } from "./ResultsComparison";
import { Button } from "@upsolve/ui";
import styled from "styled-components";
import Hotjar from "@hotjar/browser";

type OnHideFunction = (...args: any[]) => void;

interface ProductSelectorModalProps {
  onHide: OnHideFunction;
  trackingPageContext: $TSFixMe;
}

const BackButton = styled.button`
  border: 2px solid #ebefff;
  color: #777e84;
  font-size: 12px;
  background: white;
  border-radius: 4px;
  padding: 8px 8px 8px 4px;
  width: 80px !important;
  cursor: pointer;
  align-self: start;
`;

const useFlowManager = (flow: Array<keyof typeof screens | false | undefined>) => {
  const [stepIndex, setStepIndex] = useState(0);

  const onNext = () => {
    setStepIndex((oldIndex) => oldIndex + 1);
  };

  const onBack = () => {
    setStepIndex((oldIndex) => oldIndex - 1);
  };

  const onRestart = () => {
    setStepIndex(1);
  };

  const filteredFlow = flow.filter((item) => !!item);
  // const flattenedFlow = flattenDeep(flow);
  const step = filteredFlow[stepIndex];

  return { onNext, onBack, onRestart, step };
};

const reducer = (state: TFormState, update: Partial<TFormState>) => {
  return { ...state, ...update };
};

export const DebtAdvisorModal: React.FC<ProductSelectorModalProps> = ({ onHide, trackingPageContext }) => {
  const [state, dispatch] = useReducer(reducer, initialFormState);

  const flow: Array<keyof typeof screens | false | undefined> = [
    "Intro",
    "ZipcodeAsk",
    "DebtAsk",
    "HouseholdAsk",
    "HasIncomeScreen",
    state.hasIncome && "MeansTestAsk",
    "AvailableMonthlyFundsAsk",

    //failing the means test means they have significant income, so now we want
    //to see if they qualify for debt settlement
    state.passesMeansTest === false && "AccessToCapitalAsk",
    "HasGoodCreditScoreAsk",
    "CalculatingScreen",
    "StatusQuoScreen",
    "ResultsScreen",
  ];

  const { step, onNext, onBack, onRestart } = useFlowManager(flow);

  useEffect(() => {
    Hotjar.event("debtAdvisor.start");
    // Add this line for testing:
    Hotjar.event("test_run_RD_10042024");
    track(EVENTS.COMPONENT_ACTION, {
      componentName: "debtAdvisor",
      componentVersion: "1",
      actionId: `opened`,
      actionMethod: "click",
      pageContext: trackingPageContext,
    });
  }, []);

  const trackStepAction = (actionId: string, extras?: Record<string, any>) => {
    track(EVENTS.COMPONENT_ACTION, {
      componentName: `debtAdvisor.${step}`,
      componentVersion: "1",
      actionId,
      actionMethod: "click",
      pageContext: trackingPageContext,
      extras,
    });
  };

  const trackImpression = (subComponentName: string) => {
    track(EVENTS.COMPONENT_IMPRESSION, {
      componentName: `debtAdvisor.${step}.${subComponentName}`,
      componentVersion: "1",
      pageContext: trackingPageContext,
    });
  };

  const handleClose = () => {
    track(EVENTS.COMPONENT_ACTION, {
      componentName: "debtAdvisor",
      componentVersion: "1",
      actionId: `closed.${step}`,
      actionMethod: "click",
      pageContext: trackingPageContext,
    });
    onHide();
  };

  const CurrentScreen = screens[step];

  // Dropoff tracker
  useEffect(() => {
    track(EVENTS.COMPONENT_IMPRESSION, {
      componentName: `debtAdvisor.${step}`,
      componentVersion: "1",
      pageContext: trackingPageContext,
    });
  }, [step]);

  if (step === "ResultsScreen") {
    return (
      <>
        <ViewportCenteredFixed>
          <ModalClose style={{ backgroundColor: "white" }} onClick={handleClose} />
          <CurrentScreen
            controls={{ onNext, onBack, onRestart }}
            state={state}
            updateState={dispatch}
            trackStepAction={trackStepAction}
            trackImpression={trackImpression}
          />
        </ViewportCenteredFixed>
      </>
    );
  }
  return (
    <>
      <ViewportCenteredFixed>
        <TriageModal>
          {/* TODO: turn off modal close while details are open */}
          <ModalClose onClick={handleClose} />
          <TriageModalBody>
            {/* TODO: fix position of elements so things aren't jumping around everywhere
            {step !== "Intro" && <BackButton onClick={onBack}>← Back</BackButton>} */}
            <CurrentScreen
              controls={{ onNext, onBack, onRestart }}
              state={state}
              updateState={dispatch}
              trackStepAction={trackStepAction}
              trackImpression={trackImpression}
            />
          </TriageModalBody>
        </TriageModal>
      </ViewportCenteredFixed>
      <StyledOverlayBackdrop onClick={handleClose} />
    </>
  );
};
